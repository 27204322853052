import { Routes, Route} from 'react-router-dom'
import './App.css';
import Homepage from './pages/Home';
import Room from './pages/Room';
function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Homepage/>}/>
        <Route path='/room/:roomId' element={<Room/>}/>
      </Routes>
    </div>
  );
}

export default App;
