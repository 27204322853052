import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
function Homepage() {
    const [value, setValue] = useState();
    const navigate = useNavigate();
    const handleJoinRoom = useCallback(() => {
        navigate(`/room/${value}`)

    }, [navigate, value]) 

    return (
        <div>
            <input value={value} onChange={e => setValue(e.target.value)} type='text' placeholder='Enter Room Code' />
            <button onClick={handleJoinRoom}>Join</button>
            <a href="tel:+1-303-499-7111">+1 (303) 499-7111</a>
        </div>
    )
}

export default Homepage;
